<template>
  <q-banner
    :rounded="rounded"
    v-bind="$attrs"
    :class="styleClass"
    class="text-left"
  >
    <template v-if="!$q.platform.is.mobile" v-slot:avatar>
      <q-icon :name="icon || 'info'" />
    </template>
    <div class="text-body1 text-weight-medium">
      {{ title }}
    </div>
    <slot name="message">
      <div class="text-body2 message">
        {{ message }}
      </div>
    </slot>
  </q-banner>
</template>
<script>
import { textStringContrast } from 'utils/utils'

export default {
  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    icon: {
      type: String,
      default: 'info'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    borderless: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    styleClass () {
      const styleClass = ['q-py-md']
      if (this.color) {
        styleClass.push(`text-${textStringContrast(this.color)}`)
        styleClass.push(`bg-${this.color}`)
      }
      if (this.borderless) {
        styleClass.push('no-border')
      }
      return styleClass
    }
  }
}
</script>
<style lang="stylus" scoped>
  .q-banner
    border-width 1px
    border-style solid
  .message
    white-space pre-line
</style>
